import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import FeaturedList from "../components/common/featuredlist";
import SliderAndContent from "../components/common/SliderAndContent";
import Seo from "../components/seo";
import useIsVisible from "../hooks/isVisible";
import Faqs from "../components/home/faq";

const Cabanas = ({ data: { sanityCabanasPage, sanitySitesettings } }) => {
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let guide;
  let faq;

  sanityCabanasPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px]"
        h2P="pb-7"
      />
      <Logo logo={sanitySitesettings?.logo} />
      {features && <FeaturedList data={features} />}
      <div ref={elemRef}>
        {isVisible && (
          <>
            {sanityCabanasPage?.contentslider?.map((item, index) => (
              <SliderAndContent
                data={item}
                key={index}
                sliderId={`cabanas-${index}`}
                show="show-up"
              />
            ))}
            {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
            {breadcrumb && <BreadCrumb path={breadcrumb} show="show-up" />}
            <Instagram />
            {guide && (
              <>
                <CompaniesGuided data={guide} show="show-up" />
                <Logo logo={sanitySitesettings?.logo} />
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Cabanas;

export const query = graphql`
  query {
    sanityCabanasPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
      }
      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
